@import '../vars.scss';

body:not(.index) {

    $black-bar-width: 85%;

    .onloading {
        background-color: $colorLoading;
        transition: background-color 0.1s ease-in-out;
        animation: loading 0.4s infinite;

        @keyframes loading {
            0% {
              background-color: $alternateColorLoading;
            }
            50% {
              background-color: $colorLoading;
            }
            100% {
              background-color: $alternateColorLoading;
            }
        }
    }

    &#dark {
        .onloading {
          background-color: $darkColorLoading;
          animation: loadingDarkTheme 0.4s infinite;
        }

        @keyframes loadingDarkTheme {
            0% {
              background-color: $alternateDarkColorLoading;
            }
            50% {
              background-color: $darkColorLoading;
            }
            100% {
              background-color: $alternateDarkColorLoading;
            }
        }
    }

    .view:not(.blackbar, .framecv-bar) {
        -webkit-animation: fadeIn 0.5s;
        animation: fadeIn 0.5s;
    }
    
    @keyframes fadeIn {
        0% {
            opacity: 0;
            scale: 0.9;
        }
    
        100% {
            opacity: 1;
        }
    }
    
    #container-cv.view .blackbar{
        -webkit-animation: growBar 0.5s forwards;
        animation: growBar 0.5s forwards;
    }
    
    #container-cv.hide .blackbar{
        width: 0;
    }
    
    $initial-bar-width-before-animation: calc($black-bar-width - 50%);
    
    #container-cv-text-bar.view .framecv-bar{
        -webkit-animation: growCvBar 0.5s;
        animation: growCvBar 0.5s;
    }
    
    #container-cv-text-bar.hide .framecv-bar{
        width: $initial-bar-width-before-animation;
    }
    
    @keyframes growBar {
        0% {
            width: 0;
        }
    
        100% {
            width: $black-bar-width;
        }
    }
    
/*     @keyframes growCvBar {
        0% {
            width: $initial-bar-width-before-animation;
        }
        100% {
            width: $cv-bar-width;
        }
    } */
    
    @keyframes fadeInNoScale {
        0% {
            opacity: 0.15;
            scale: 0.9;
        }
    
        100% {
            opacity: 1;
        }
    }
}