$anim-duration: 250ms;
$background-opacity-duration: 400ms;

body.home {
    .project-page-container {

        &::-webkit-scrollbar {
            display: none;
        }

        left: 0;
        top: 0;
        min-height: 100%;
        width: 100%;
        height: fit-content;
        z-index: 9;
        display: none;
        width: 100vw;
        position: fixed;
        height: 100vh;
        transition: opacity $anim-duration ease;
        background-color: rgba(0, 0, 0, 0.9);
        backdrop-filter: blur(3px);
        transition: opacity $background-opacity-duration ease;

        .wmde-markdown {
            background-color: transparent !important;
        }

        .current-project-viewing {
            transform: translateY(40vh);
        }

        .quit-project-button {
            transform: translateY(-5vh);
        }

        .project-viewer-container {
            width: 100vw;
            height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
            z-index: -1;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: opacity 0.3s ease-in-out;
            opacity: 0;

            &.visible {
                z-index: 100;
                opacity: 1;
            }

            &.hidden {
                z-index: 100;
                opacity: 1;
                animation: disappear 0.3s forwards;

                @keyframes disappear {
                    to {
                        opacity: 0;
                    }
                }
            }

            .cross-project-viewer {
                $cross-project-viewer-width: 80px;

                right: #{-1 * ($cross-project-viewer-width / 2)};
                position: absolute;
                top: #{-1 * ($cross-project-viewer-width / 2)};
                width: $cross-project-viewer-width;
                height: $cross-project-viewer-width;
                transform: rotate(45deg);
                background-color: #de9fae;
                transition: background-color 0.2s ease-in-out;

                &:hover {
                    cursor: pointer;
                    background-color: #c87589;
                }
            }

            .project-viewer {
                width: 95%;
                height: 95%;
                border: 15px solid black;
                border-radius: 30px;
            }
        }

        .quit-project-button {
            position: fixed;
            right: 2vw;
            top: 2vw;
            display: flex;
            color: white;
            width: 70px;
            height: 70px;
            border: 1px solid rgba(221, 141, 141, 0.8);
            border-radius: 50%;
            font-size: 37px;
            cursor: pointer;
            transition: 0.2s;
            justify-content: center;
            align-items: center;
            z-index: 11;
            font-weight: bold;
            transform: translateY(-20vh);
            &:hover {
                border: 1px solid transparent;
                background-color: rgba(209, 95, 95, 0.8);
                transition: 0.2s;
            }
        }

        *:not(.project-viewer-container, .project-viewer-container > *) {
            transition: transform 0.5s ease-in-out;
        }

        &.visible {
            display: block;
            overflow-y: scroll;
            scrollbar-width: none;

            .quit-project-button {
                animation: 0.3s unTranslateY forwards;
            }

            .current-project-viewing {
                animation: 0.5s unTranslateY forwards;
            }

            .background-project-page {
                animation: appear forwards 0.4s;

                @keyframes appear {
                    to {
                        opacity: 1;
                    }
                }
            }

            .project-page-content {
                *:not(.project-viewer-container, .project-viewer-container > *) {
                    animation: 0.3s unTranslateY forwards;
                }

                .parts > div:last-child {
                    margin-bottom: 1.5vw;
                }
            }

            @keyframes unTranslateY {
                to {
                    transform: translateY(0);
                }
            }
        }

        &.hidden {
            & {
                display: block;
            }

            .background-project-page {

                opacity: 1;
                animation: disappear 0.5s forwards;

                @keyframes disappear {
                    from {
                        opacity: 1;
                    }
                    to {
                        opacity: 0 !important;
                    }
                }
            }

            .project-page-content {
                *:not(.project-viewer-container, .project-viewer-container > *) {
                    animation: translateY 0.7s forwards;
    
                    @keyframes translateY {
                        from {
                            transform: translateY(0);
                        }
                        to {
                            transform: translateY(100vh);
                        }
                    }
                }
            }
        }

        $space-border-actual-project-viewing: 4vw;

        .current-project-viewing {
            position: fixed;
            z-index: 13;
            width: fit-content;
            height: fit-content;
            transition: all 3.5s;
            bottom: $space-border-actual-project-viewing;
            right: $space-border-actual-project-viewing;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 10vw;
            height: 10vw;
            border-radius: 32.5%;
            background-color: rgba(235, 208, 208, 0.6) !important;
            box-shadow: 1px 0 0 0 rgba(235, 208, 208, 0.6);
            transition: scale 3.5s ease-in-out;
            scale: 0.95;
            & > img {
                width: 75%;
                height: 75%;
                transition: transform 3.5s ease-in-out;
                transform: scale(1.2);
            }

            &.animate {
                scale: 1.05;
                box-shadow: 0 0 0 0 rgba(235, 208, 208, 0.6);

                & > img {
                    transform: scale(0.95);
                }
            }
            
        }

        &::-webkit-scrollbar {
            width: 0;
        }

        $padding-left-project-page-content : 2.5vw;

        .project-page-content {

            *:not(.project-viewer-container, .project-viewer-container > *) {
                transform: translateY(100vh);
            }

            left: 0;
            top: 0;
            width: calc(100% - $padding-left-project-page-content);
            padding-left: $padding-left-project-page-content;
            padding-top: $padding-left-project-page-content;
            z-index: 11;

            .background-project-page {
                background-color: rgba(0, 0, 0, 0.93);
                backdrop-filter: blur(3px);
                width: 100%;
                height: 100%;
                position: fixed;
                top: 0;
                left: 0;
                z-index: -1;
                opacity: 0;
            }

            .title-page-project {
                font-size: 3vw;
                font-weight: 300;
                margin: 0;
                padding: 0;
                margin-bottom: 1.5vw;
                z-index: 11;
                display: flex;
                flex-direction: row;
                align-items: center;
                column-gap: 0.9vw;
            }

            .title-page-project {
                & > p {
                    color: rgb(232, 190, 190) !important;
                    margin: 0;
                }
                & > img {
                    margin-top: 0.2vw;
                    width: 3vw;
                    height: 3vw;
                }
            }

            .page-content {
                color: white;
                font-size: 1.8vw;
                font-weight: 300;
                line-height: 1.5;
                margin: 0;
                padding: 0;
                margin-top: 1vw;
                margin-right: calc(3vw + 70px);
                margin-left: 5vw;
                margin-bottom: 1vw;
            }

            .project-use-desc {
                text-align: justify;
            }

            .project-repository {
                .project-repository-value {
                    width: fit-content;
                    display: flex;
                    align-items: center;
                    column-gap: 10px;
                    p {
                        margin: 0;
                        padding: 0;
                    }
                    img {
                        transition: 0.3s;
                        width: 2vw;
                        height: 2vw;
                    }

                    &:hover {
                        img {
                            $scale: 0.9;
                            scale: $scale;
                            transform: scale($scale);
                        }
                    }
                }
            }

            .link-btn, .download-btn {
                width: fit-content;
                color: white !important;
                font-size: 2.8vw;
                margin-left: 2.5vw;
                margin-bottom: 0;
                font-weight: 300;
                line-height: 1.8;
                text-decoration: underline;
                cursor: pointer;
                text-decoration-color: rgb(232, 190, 190);
                text-decoration-thickness: 0.2vw;
                text-underline-offset: 0.5vw;
            }

            .link-btn:hover,
            .download-btn:hover {
                color: rgb(234, 215, 215) !important;
                transition: 0.2s;
            }

            .project-size-container {
                margin-bottom: 2vw;
            }

            .title-project-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                column-gap: 0.9vw;
            }

            .title-project-container>p {
                margin: 0;
                padding: 0;
                margin-bottom: 1.5vw;
                z-index: 11;
                font-size: 3.6vw;
                font-weight: 300;
                color: rgb(255, 255, 255);
                text-decoration: underline;
                text-decoration-color: rgb(232, 190, 190);
                text-decoration-thickness: 0.3vw;
                text-decoration-style: wavy;
                text-underline-offset: 0.5vw;
            }

            .title-project-container>img {
                margin-top: 0.2vw;
                width: 4vw;
                height: 4vw;
                margin-bottom: 0.25vw;
            }

            .project-languages-skills {
                display: flex;
                flex-direction: row;
                align-items: center;
                column-gap: 0.9vw;
                margin-bottom: 1.5vw;
                width: fit-content;
            }

            .project-languages-skills>img {
                margin-top: 0.2vw;
                width: 2.6vw;
                height: 2.6vw;
                margin-bottom: 0.25vw;
            }

            .skill-language-container>p {
                margin: 0;
                padding: 0;
                font-size: 2.3vw;
                font-weight: 100;
                color: rgb(255, 255, 255);
            }

            .skill-language-container {
                width: fit-content;
                padding: 7px 20px;
                border-radius: 15px;
                transition: 0.3s linear;
                cursor: default;
            }

            .skill-language-container:hover {
                filter: brightness(0.85);
                transition: 0.3s linear;
            }

            .project-languages-skills-container {
                margin-top: 2vw;
                margin-bottom: 3vw;
                width: fit-content;
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                column-gap: 2vw;
                row-gap: 2vw;
            }

            .project-size-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                column-gap: 0.6vw;
                margin-bottom: 3vw;
                margin-top: 0.7vw;
                margin-left: 3.5vw;
            }

            .project-size-container>p {
                margin: 0;
                padding: 0;
                font-size: 2.3vw;
            }

            .project-size-container>img {
                object-fit: cover;
                margin-top: 0.2vw;
                width: 3vw;
                height: 3vw;
                margin-bottom: 0.25vw;
            }

            .title-language-skill {
                margin: 0;
                padding: 0;
            }
        }
    }
}