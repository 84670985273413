@import '../vars.scss';

body.background#dark {
    
    *:not(.page-title-part) {
        color: white !important;
    }
    
    .page-title-part {
        color: rgb(232, 190, 190) !important;
    }
    
    .startbackground {
        background-color : rgb(0 0 0) !important;
    }
    
    .title-page-semester {
        color : rgb(232, 190, 190) !important;
    }
    
    .explain-container {
        .explain {
            background-color : $colored-container-background-color-dark;

            a:hover {
                background-color: $colored-containers-background-color-hover-dark;
            }
        }
        
    }

    $bottom-semester-background-color-dark : #dfcace;
    
    .semester {
        &.selected {
            box-shadow: 0 0 0 4px $bottom-semester-background-color-dark;
            background-color : $bottom-semester-background-color-dark;
        }

        &:not(.selected) {
            &:hover {
                background-color:rgba(245, 239, 239, 0.7);
            }
        }

        .bottom-semester-container {
            .bottom-semester {
                background-color: $bottom-semester-background-color-dark;
            }
        }

        * {
            color: black !important;
        }
    }
    
    #timeline::before {
        background: linear-gradient(50deg, rgba(255, 255, 255, 0.2) 40%, rgba(218, 134, 134, 0.8) 50%, rgba(255, 255, 255, 0.4) 60%);
    }
    
    .point-container {

        &::after {
            background-color: rgb(255 255 255 / 60%);
            color: black;
            background-image: url(../../img/background/calendar-black.png);
        }

        .point {
            background: linear-gradient(160deg, transparent 60%, rgb(255, 255, 255) 40%) !important;
        }

        &:hover, &.focus {
            .point {
                background : linear-gradient(160deg, transparent 60%, $bottom-semester-background-color-dark 40%) !important;
            }
        }


    }
}