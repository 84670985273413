@import '../vars.scss';

body {
    /* CSS des erreurs et des messages de succès */
    .message-container {
        // On précise le important pour margin et padding pour la page index qui les définit à 0 globalement
        position: absolute;
        z-index: 100;
        top: 70px;
        right: 30px;
        width: 300px;
        height: fit-content;
        min-height: 85px;
        padding: 12px 45px !important;
        border-radius: 10px;
        font-size: 15px;
        color: #fff;
        text-align: center;
        margin-top: 10px !important;
        box-shadow: $modal-box-shadow;
        display: grid;
        grid-template-columns: auto 1fr; 
        column-gap: 20px;
        align-items: center;
        justify-content: center;
        backdrop-filter: blur(5px);
        opacity: 0;
        animation: 0.3s messageAnimation 0s ease-in-out forwards;

        /* CSS des erreurs */
        &.error-message-container {
            background-color: $modal-error-color;

            img {
                width: 30px;
                height: 30px;
            }
        }

        /* CSS des messages de succès */
        &.success-message-container {
            background-color: $modal-success-color;

            img {
                width: 50px;
                height: 50px;
            }
        }

        &.fade-out {
            opacity: 1;
            animation: message-fade-out 0.2s ease-in-out forwards;
        }

        p {
            font-size: 17px;
            color: #fff;
            font-weight: bold;
            text-align: center;
            font-family: "Poppins", sans-serif;
        }

        @keyframes messageAnimation {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }

        @keyframes message-fade-out {
            0% {
                opacity: 1
            }
            100% {
                opacity: 0;
            }
        }
    
        /* CSS des cross sur les messages de retour */
        .cross-container {
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;

            .cross {
                width: 20px;
                height: fit-content;
                font-size: 25px;
                font-weight: bold;
                text-decoration: none;
                display: flex;
                justify-content: center;
                align-items: center;

                .bar {
                    width: 100%;
                    height: 3.5px;
                    background-color: #fff;
                    position: absolute;
                    border-radius: 3px;
                    top: 50%;
                    left: 0;
                    transition: 0.2s;

                    &:first-child {
                        transform: rotate(45deg);
                    }

                    &:last-child {
                        transform: rotate(-45deg);
                    }
                }

                &:hover {
                    .bar {
                        background-color: #ececec;
                        transition: 0.2s;
                    }
                }
            }
        }
    }
}