body.about {
    @media screen and (min-height: 1024px) {
        .content {
          margin-top: 7vh;
        }
      }
      
      @media screen and (max-width: 1024px) {
          .first-bar {
              width: 20%;
          }
      
          @media screen and (max-width: 768px) {
              .content {
                  width: 95%;
              }
      
              .title {
                  font-size: 4vw;
              }
      
              .title-container > img {
                  width: 4.5vw;
                  height: 4.5vw;
              }
      
              .subtitle {
                  font-size: 3vw;
              }
      
              .text-content {
                  font-size: 2.5vw;
              }
      
              .bar {
                  height: 1vw;
              }
      
              @media screen and (max-width: 448px) {
                  .content {
                      margin-bottom: 5vh;
                  }
      
              }
          }
      }
}