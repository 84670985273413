body:not(.index) {
    *.hide:not(.project, .card) {
        opacity: 0;
        scale: 0.9;
    }

    .card.hide {
        opacity: 0;
    }
    
    .project.hide {
        opacity: 0;
    }
}