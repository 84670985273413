#root {
    height: fit-content;
    width: 100%;
    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    scrollbar-width: thin;
}