@import '../vars.scss';

body:not(.index) {
    /* Base du menu */
    .menu {
        list-style-type : none;
        display: flex;
        margin-top : 0;
        font-weight : bold;    
        background-color: $components-background-color; 
        transition: background-color $general-transition-switch-mode;
        backdrop-filter: blur(5px);
        justify-content : flex-end;
        height: 67px;
        align-items: center;
        letter-spacing: 0.4px;
        box-shadow: $components-box-shadow;

        /* Ecart entre les endroits */
        li {

            display : inline;
            position: relative;
            padding-inline: 20px;

            /* Le bas de chaque case survolée s'agrandit de 10px mais pas le reste */
            &:hover{
    
                $hover-color: black;
                $bar-height: 3px;
    
                &::after {
                    content: '';
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: $bar-height;
                    bottom: 0;
                    left: 0;
                    transform: translateY(100%);
                    $border-radius: 4px;
                    border-bottom-left-radius: $border-radius;
                    border-bottom-right-radius: $border-radius;
                    background-color: $hover-color;
                    transition: height 0.2s;
                }
    
                p {
                    color: $hover-color;
                }
            }
        }

        /* Disposition et personnification des items */
        .sites {
            display : block;
            min-width: 100px;
            text-decoration: none;
            text-transform: uppercase;
            text-align: center;
            padding : 5px;

            & > p {
                color: black;
                font-family: 'Poppins', sans-serif;
                letter-spacing: 0.7px;
                font-weight: bold;
            }
        }
    }


    /* Mise en place du menu sticky */
    header{
        position : fixed;
        top : 0;
        width : 100vw;
        z-index: 2;
    }

    /*  Ecart du debut de page avec le menu */
    body {
        overflow-x : hidden;
    }

    /* Mettre droit l'image du menu */
    .logo {
        position: absolute;
        max-width: 60px;
        left: 1%;
        width: 50px;
        height: 50px;
        background-image: url(../../img/header/portfolio_logo.png);
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
    }

    /* Mettre le titre assez bas pour qu'on puisse le voir */
    .t1 {
        padding-top: 70px;
    }

    .mediamenu{
        display: none;
    }

    /* Disposition de l'icone darkmode */

    .theme-form {
        width: 5%;
        height:70px;
        margin-right: 3.2vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .mode-button{
        background-color: transparent;
        border: none;
        cursor: pointer;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        transition: 0.3s;
        background-image: url(../../img/header/dark-mode.png);
        width: 40px;
        height: 40px;
    }

    .mode-button:hover {
        background-image: url(../../img/header/dark-mode-hover.png);
    }

    .menu-logo-container {
        width: 0;
        height: 100%;
        display: flex;
        align-items: center;
    }
}