@import '../vars.scss';

body.contact {
    form {
      width: 400px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      row-gap: 15px;

      .required{
          color:#ff0000;
      }

      .buttons-container {
        button {
            transition: background-color 0.4s ease-in-out;
            background: $form-submit-button-background-color;
            padding: 8px 15px 8px 15px;
            border: none;
            color: #fff;
            text-align: center;

            &:hover {
                background: $form-submit-button-background-color-hover;
                box-shadow:none;
                -moz-box-shadow:none;
                -webkit-box-shadow:none;
                color: white;
            }
          }
      }

      .input-container {

        display: flex;
        flex-direction: column;
        row-gap: 10px;

        label {
          font-weight: bold;
          margin: 0;
        }

        input, textarea{

          box-sizing: border-box;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          padding: 8px;
          margin:0px;
          width: 100%;
          backdrop-filter: blur(15px);
          border: none;
          outline: none;
          border-radius: 10px;
          background-color: transparent;

          &::-webkit-scrollbar {
            display: none;
          }

          &:focus {
            $boxShadowColor: $form-submit-button-background-color;
            animation: appearShadow 0.3s forwards;
            @keyframes appearShadow {
              from {
                box-shadow: 0 0 0 0 $boxShadowColor;
              }
              to {
                box-shadow: 0 0 0 2.5px $boxShadowColor;
              }
            }
          }
        }

        input {
          $input-vertical-padding: 10px;
          padding-top: $input-vertical-padding;
          padding-bottom: $input-vertical-padding;
        }

        textarea{
          height: 100px;
          resize: none;
        }
      }
      .error{
        color: rgb(227, 153, 100);
        font-size: 13px;
        font-weight: 500;
      }
    }

  /* Définition case rose avec texte */
  .parent {
    width: 900px;
    height: 600px;
    margin: 10px;
  }

  /* On définit les couleurs de la case */
  #pres {
    width: 500px;
    display: grid;
    grid-template-rows: 1fr 1.2fr;
    align-items: center;
    background-color: $colored-container-background-color;
    transition: background-color $general-transition-switch-mode;
    box-shadow: $colored-container-box-shadow; 
    border-radius: 20px;
  }

  /* On définit les paramètres du titre*/
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.884);
    margin: 0 auto;
    padding-top: 20px;
  }

  /* On définit les paramètres du texte*/
  .present{
    color : white;
    font-size: 20px;
    text-align: justify;
    padding: 0 25px;
    font-weight: 400;
  }

  /* On définit les paramètres de la photo*/
  #imgcontact{
    width: 150px;
    height: 150px;
    margin: 0 auto;
    margin-top: 20px;
    border-radius: 10%;
  }

  /* Lorsque l'utilisateur vient de répondre au formulaire, on lui affiche un message, voici le style de ce message*/
  #hasSend {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
    font-size: 40px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.884);
    img{
      width: 70px;
      height: 70px;
    }
  
    p {
      color : rgb(0, 0, 0);
      font-size: 30px;
      text-align: justify;
    }
  }

  /* Flex des principaux elements de la page */
  main#contactPage {
    width: 100%;
    margin-top : 100px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: center;
    column-gap: 50px;
  }

  .pres-container, .form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;

    &.pres-container {
      align-items: end;
    }

    &.form-container {
      align-items: start;
    }
  }


  .buttons-container > button {
    width: 100%;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 16px;
  }

  /* On met un ecart entre le footer et le reste */
  footer {
    margin-top: 7vh;
  }

  .nb-chars-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    column-gap: 5px;
    height: fit-content;
    margin-top: 0.3vw;
    opacity: 0;
    transition: opacity 0.4s, transform 0.1s;
    cursor: default;

    &.visible {
      opacity: 1;
    }

    & > p {
      font-size: 15.5px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.884);
    }

    & > .to-modify {
      margin : 0 0 0 1vw;
      display: block;
    }

    & > .nb-chars-left-text {
      margin : 0;
      transition: transform 0.1s ease-in;

      &.scale {
        transform: scale(1.05);
      }
    }

    .spinner {
      width: fit-content;
      text-align: center;
      transform: translateY(18%);
      transition: opacity 0.2s;
      opacity: 1;

      & > div {
        width: 18px;
        height: 18px;
        background-color: rgb(0, 0, 0);

        border-radius: 100%;
        display: inline-block;
        -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
        animation: sk-bouncedelay 1.4s infinite ease-in-out both;
      }

      @-webkit-keyframes sk-bouncedelay {
        0%, 80%, 100% { -webkit-transform: scale(0) }
        40% { -webkit-transform: scale(0.7) }
      }
    
      @keyframes sk-bouncedelay {
        0%, 80%, 100% { 
          -webkit-transform: scale(0);
          transform: scale(0);
        } 40% { 
          -webkit-transform: scale(0.7);
          transform: scale(0.7);
        }
      }

      .bounce1 {
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
      }

      .bounce2 {
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
      }
    }

  }

  /* Ajout cas blanche autour du formulaire */
  .formulaire{
    display: flex;
    align-items: center;
    padding: 40px;
    width: fit-content;
    background-color: rgba(255,255,255,0.5);
    transition: background-color $general-transition-switch-mode;
    backdrop-filter: blur(3px);
    border-radius: 20px;
    box-shadow: $form-box-shadow; 

    /* Centrer le formulaire */
    & > form {
      width: 400px;
      margin: 0 auto;
    }
  }

  /* On met le container principal en de l'erreur en fixed */
  .alert-container {
    position: fixed;
    top: 10px;
    right: 0;
    z-index: 10000;
    width: fit-content;
    height: fit-content;
  }
}