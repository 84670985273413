@import '../vars.scss';

body:not(.index)#dark {
    .menu {
        background-color: $components-background-color-dark;
        box-shadow: $components-box-shadow-dark;

        li:hover{
            &::after {
                background-color: white;
            }
        }
    
        .sites > p {
            color: white;
        }
    }


    .logo {
        background-image: url(../../img/header/dark-theme/dark_portfolio_logo.png);
    }

    .mode-button {
        background-image: url(../../img/header/light-mode.png);
        width: 40px;
        height: 40px;

        &:hover {
            background-image: url(../../img/header/light-mode-hover.png);
        }
    }

}