body.home {
    /* Définir la taille du bouton fermer */
    #cross{
        display: flex;
        color: white;
        width: 70px;
        height: 70px;
        z-index: 3;
        border: 1px solid rgba(221, 141, 141, 0.8);
        border-radius: 50%;
        font-size: 45px;
        cursor: pointer;
        transition: 0.2s;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
    }

    /* Centrer le bouton fermer */
    #cross p {
        margin-top : 50%;
    }

    /* Définir la taille du bouton imprimer & télécharger & informations*/
    #download, #print, #infos{
        display: flex;
        color: white;
        width: 70px;
        height: 70px;
        z-index: 3;
        border: 1px solid rgba(221, 141, 141, 0.8);
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        font-size: 35px;
        cursor: pointer;
        transition: 0.2s;
    }

    #infos{
        border: 1px solid rgba(240, 193, 193, 0.8);
    }

    /* Définir le hover des boutons imprimer, télécharger & informations */
    #download:hover, #print:hover{
        background-color: rgba(221, 141, 141, 0.8);
    }

    #infos:hover{
        background-color: rgba(240, 193, 193, 0.8);
    }
    /* Hover de la croix */
    #cross:hover{
        border: 1px solid transparent;
        background-color: rgba(209, 95, 95,0.8);
        transition: 0.2s;
    }

    /* Changer la police */
    #framecv-visible {
        font-family: 'Roboto', sans-serif;	
        font-weight: bold;
        overflow-y: scroll;
    }

    /* Changer la taille des boutons */
    #imgbutton{
        width: 35px;
    }

    /* Disposition de la page quand on clique sur le CV */
    #framecv-visible {

        $anim-duration-frame-cv : 150ms;

        & {
            position: fixed;
            width: 100%;
            height: 100%;
            z-index: 5;
            top: 0;
            left: 0;
            transition: opacity 0.5s ease-in-out;
            display: none;
        }

        /* Mettre un display flex sur les boutons */
        #buttons {
            position: fixed;
            top: 0;
            right: 0;
            display: flex;
            flex-direction: column;
            row-gap: 30px;
            margin-top: 40px;
            margin-right: 40px;
            z-index: 4;
        }

        /* Définir le background de la page */
        #backgroundCV { 
            background-color: rgba(0, 0, 0, 0.92);
            backdrop-filter: blur(3px);
            position: fixed;
            width: 100%;
            height: 100%;
            z-index: -1;
            top: 0;
            left: 0;
            opacity: 0;
            transition: opacity 0.4s ease-in-out;
        }

        /* Définir la taille du CV  */
        #imgcv img {
            margin-top : 75px;
            border-radius: 30px;
            width: 75%;
            z-index: 2;
        }
    
        /* Centrer l'image du CV */
        #imgcv {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        /* Disposer la barre avec le reste */
        #container-cv-text-bar{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        /* Pour l'animation d'ouverture et de fermeture*/

        #imgcv, #container-cv-text-bar {
            transition: transform $anim-duration-frame-cv ease;
            transform: translateX(-110vw);
        }
        
        #buttons {
            transition: transform $anim-duration-frame-cv ease-in-out;
            transform: translateX(15vw);
        }

        @keyframes opacityUp {
            100% {
                opacity: 1;
            }
        }

        @keyframes opacityDown {
            100% {
                opacity: 0;
            }
        }

        @keyframes removeTranslate {
            100% {
                transform: translateX(0);
            }
        }

        @keyframes setTranslateButtons {
            100% {
                transform: translateX(15vw);
            }
        }

        @keyframes setTranslateOthers {
            100% {
                transform: translateX(-110vw);
            }
        }

        &.visible {

            & {
                display: block;
            }

            #backgroundCV {
                animation: opacityUp forwards $anim-duration-frame-cv;
            }

            #buttons {
                animation: removeTranslate forwards calc($anim-duration-frame-cv + $anim-duration-frame-cv/2);
            }

            #imgcv, #container-cv-text-bar {
                animation: removeTranslate forwards calc($anim-duration-frame-cv + $anim-duration-frame-cv/2);
            }
        }

        &.hidden {
            & {
                display: block;
            }

            #backgroundCV {
                opacity: 1;
                animation: opacityDown forwards $anim-duration-frame-cv;
            }

            #buttons {
                transform: translateX(0);
                animation: setTranslateButtons forwards calc($anim-duration-frame-cv + $anim-duration-frame-cv/2);
            }

            #imgcv, #container-cv-text-bar {
                transform: translateX(0);
                animation: setTranslateOthers forwards calc($anim-duration-frame-cv + $anim-duration-frame-cv/2);
            }
        }
    }

    #framecv-visible::-webkit-scrollbar {
        display: none;
    }

    /* Changer la couleur et centrer le texte explicatif d'en bas */
    #framecv-text{
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top : 60px;
        margin-left: 12%;
        margin-right: 12%;
    }

    #framecv-text p{
        margin-top: 3%;
        text-align: center;
        margin-bottom: 10%;
        font-size: 25px;
    }

    /* Créer la barre blanche de séparation entre CV et le text*/
    $cv-bar-width: 75%;
    .framecv-bar{
        display: flex;
        justify-self: center;
        width: $cv-bar-width;
        height: 5px;
        background-color: white;
        border-radius: 5px;
    }

    a{
        text-decoration: none;
        color: white;
    }

    /* Mettre un margin-bottom sur la dernière barre avec la classe blackbar */
    .framecv-bar:first-child{
        margin-top: 5%;
    }

    .framecv-bar:last-child{
        margin-bottom: 7%;
    }

    /* CSS de l'onglet informations qui apparait quand on clique sur le bouton informations */
    #informations{
        display : none;
        position: fixed;
        opacity: 0;
        width: 375px;
        height: 350px;
        z-index: 3;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 0;
        background: linear-gradient(to right, rgba(240, 193, 193, 0.8), rgba(232, 216, 216, 0.8));
        border: 2px solid rgba(234, 205, 205, 0.8);
        transition:0.2s;
        border-radius: 90px 130px 0 130px;
        margin-right : 75px;
        margin-top : 10px;
        /* Bout d'une bulle en bas à droite */

        &.visible {
            z-index : 5;
            display : flex;
            opacity : 1;
        }

        div{
            padding-left: 10%;
            padding-right: 10%;
            font-size: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            column-gap: 5px;
            color: rgb(255, 255, 255);
            text-shadow: 1px 0 0 black, 1px 1px 0 black, 0 1px 0 black, -1px 1px 0 black, -1px 0 0 black, -1px -1px 0 black, 0 -1px 0 black, 1px -1px 0 black;
        }
    }
}