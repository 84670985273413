body:not(.index) {
    @media (max-width: 700px){
        .menu{
            display: none;
        }

        .mediamenu{
            list-style-type : none;
            display: flex;
            flex-direction: row;
            column-gap: 20px;
            margin-top : 0;
            font-weight : bold;    
            justify-content : end;
            height: 50px;
            background-color: rgba(255, 255, 255, 0.9); 
            backdrop-filter: blur(2px);
            align-items: center;
            padding-right: 10px;
            box-shadow: 0 0 40px rgb(0 0 0 / 20%);
        }

        .mediamenu > * {
            transition: 0.1s;
        }
        
        &#dark .mediamenu {
            background-color: rgba(0, 0, 0, 0.9);
            backdrop-filter: blur(5px);
            box-shadow: 0 0 60px rgba(255, 255, 255, 0.1);
        }

        #personalsite {
            height: 60%;
        }

        /* Images du menu en dark */
        &#dark .mediamenu > #receptionsite{
            background-image: url(../../../img/header/dark-theme/reception_logo.png) !important;
        }

        &#dark .mediamenu > #receptionsite:hover{
            background-image: url(../../../img/header/dark-theme/reception_logo_hover.png) !important;
        }

        &#dark .mediamenu > #contactsite{
            background-image: url(../../../img/header/dark-theme/contact_logo.png) !important;
        }

        &#dark .mediamenu > #contactsite:hover{
            background-image: url(../../../img/header/dark-theme/contact_logo_hover.png) !important;
        }

        &#dark .mediamenu > #personalsite {
            background-image: url(../../../img/header/dark-theme/personal_logo.png) !important;
        }

        &#dark .mediamenu > #personalsite:hover {
            background-image: url(../../../img/header/dark-theme/personal_logo_hover.png) !important;
        }

        &#dark .mediamenu > #backgroundsite {
            background-image: url(../../../img/header/dark-theme/cv_logo.png) !important;
        }

        &#dark .mediamenu > #backgroundsite:hover {
            background-image: url(../../../img/header/dark-theme/cv_logo_hover.png) !important;
        }

        /* Images du menu en light */
        .mediamenu > #receptionsite{
            background-image: url(../../../img/header/reception_logo.png);
        }

        .mediamenu > #receptionsite:hover{
            background-image: url(../../../img/header/reception_logo_hover.png);
        }

        .mediamenu > #contactsite{
            background-image: url(../../../img/header/contact_logo.png);
        }

        .mediamenu > #contactsite:hover{
            background-image: url(../../../img/header/contact_logo_hover.png);
        }

        .mediamenu > #personalsite {
            background-image: url(../../../img/header/personal_logo.png);
        }

        .mediamenu > #personalsite:hover {
            background-image: url(../../../img/header/personal_logo_hover.png);
        }

        .mediamenu > #backgroundsite {
            background-image: url(../../../img/header/cv_logo.png);
        }

        .mediamenu > #backgroundsite:hover {
            background-image: url(../../../img/header/cv_logo_hover.png);
        }

        .media-theme-form {
            width: fit-content;
            height: 100%;
            margin-right: 3.2vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .media-theme-form > button {
            width: 35px;
            height: 100%;
            border: none;
            background-color: transparent;
            cursor: pointer;
        }

        .logo{
            display: block;
            position: absolute;
            width: 40px;
            height: 40px;
            top: 5px;
            left : 5px;
        }

        .mediasites {
            display : block;
            width: 35px;
            height: 35px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        @media screen and (max-width: 425px) {
            .mediamenu > a:first-child{
                display: none;
            }

            .mediamenu > form:last-child{
                margin-right: 0;
            }

            .mediamenu {
                column-gap: 10px;
                width: 100%;
                justify-content: space-evenly;
                padding-inline-start: 0;
            }
        }
    }
}