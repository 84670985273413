@media screen and (max-aspect-ratio : 3/2) and (max-width : 1024px) {
    /* On flex le tout pour mettre ca en colonne */
    #container{
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        align-items: center;
        justify-content: center;
        margin-top : 6vh;

    }
    
    /* Changer disposition des boutons */
    #buttons{
        display: flex;
        flex-direction: row;
        column-gap: 10vw;
        row-gap: 40px;
        right: auto;
        margin-left: 25px;
        z-index: 4;
    }

    #cross{
        margin-top: 0;
    }

    /* On redimensionne l'image du cv */
    #imgcv img{
        width:95vw;
    }

    /* On active le background color pour qu'on puisse voir les boutons correctement */
    #download, #print{
        background-color: rgba(221, 141, 141, 0.8);
    }
    
    #infos{
        background-color: rgba(240, 193, 193, 0.8);
    }
    /* Hover de la croix */
    #cross{
        background-color: rgba(209, 95, 95, 0.8);
        
        &:hover{
            background-color: rgba(209, 95, 95);
        }
    }

    #download:hover, #print:hover{
        background-color: rgba(221, 141, 141);
    }
    
    #infos:hover{
        background-color: rgba(240, 193, 193);
    }
    
    /* On change le style des informations */
    #informations{
        margin-top: 15vh;
        border-radius: 0;
        width: 100vw;
        height: 40vh;
        margin-right: 0;
        margin-left: 0;
        border-radius: 0 70px 0 110px;

        p{
            font-size: 4vw;
        }
    }

    @media screen and (max-height : 1100px ){
        /* On positionne les boutons correctement et on les redimensionne */
        #buttons{
            column-gap: 4vw;
            margin-left: 40px;
        }

        #buttons>*, #download{
            width: 50px;
            height: 50px;
        }

        #imgbutton{
            max-width: 25px;
            max-height: 25px;
        }

        #cross p {
            font-size: clamp(2rem, 2vw, 2rem);
        }

        /* On change la taille du texte */
        #framecv-text p{
            font-size: clamp(0.8rem, 2vw, 2rem);
            padding-bottom: 40px;
        }

        #informations p{
            font-size: 5vw;
            text-shadow: 0.7px 0 0 black, 0.7px 0.7px 0 black, 0 0.7px 0 black, -0.7px 0.7px 0 black, -0.7px 0 0 black, -0.7px -0.7px 0 black, 0 -0.7px 0 black, 0.7px -0.7px 0 black
        }
    }
}