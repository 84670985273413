@import '../vars.scss';

body.index#dark{
    color: #ffffff;

    .background-container {
        background-color: $index-background-color-dark;
    }

    .header-triangle {
        border-top-color: $index-triangle-header-background-color-dark;
    }

    .footer-triangle {
        border-bottom-color: $index-triangle-footer-background-color-dark;
    }

    .get-start:hover {
        background-color: rgba(48, 5, 43, 0.3);
        color: rgb(255, 255, 255);
    }

    .about-page {
        & > p {

            &::after  {
                background-color: #30052b;
            }

            &:hover::after {
                background-color: #510548;
            }

        }
    }

    .circle-one {
        background-color: $index-circle-one-background-color-dark;
    }

    .circle-two {
        background-color: $index-circle-two-background-color-dark;
    }

    .triangle-orange {
        border-bottom-color: $index-triangle-orange-background-color-dark;
    }

    .triangle-red {
        border-bottom-color: $index-triangle-red-background-color-dark;
    }

    .triangle-yellow {
        border-bottom-color: $index-triangle-yellow-background-color-dark;
    }

    .menu {
        ul li a::after {
            background-color: #ead3d3;
        }
        li a:hover {
            color: #ead3d3;
        }
    } 

    .dl-img-hover {
        background-image: url(../../img/index/download-pink.png);
    }

    .container > .title > h1 {
        color: #fbe9e9;
    }

    .vertical-bar {
        background-color: #ffffff;
    }

    .select-language {
        .choice {
            background-color: rgba(0, 0, 0, 0.2);
            .options {
                .option {
                    &:hover {
                        background-color: #e6c6be38;
                    }
                }
            }
        }
    }

    .btn-switch-mode {

        button {
            border: 1.5px solid #ffffff;
        }
        & > .media-dark-theme-form > button {
            background-color: #d5c6d83f;
            background-image: url(../../img/index/dark-hover.png);
            &:hover {
                background-color: #bb93c33f;
                background-image: url(../../img/index/dark.png);
            }
        }

        & > .media-light-theme-form > button{
            background-color: transparent;
            background-image: url(../../img/index/light.png);
            &:hover {
                background-color: #e8dfea25;
                background-image: url(../../img/index/light-hover.png);
            }
        }
    }
    
}