body.myself {
    @media screen and (max-aspect-ratio : 3/2) and (max-width : 1024px) {
        
        /* Régler les iframes */
        #frames iframe{
            width: 80%;
            height: 175;
        }

        #frames{
            column-gap:0;
        }

        @media screen and (max-width : 700px) {
            /* Changement padding avec le haut de page du titre */
            .t1{
                margin-top: 70px;
            }
            #content{
                display:flex;
                flex-direction: column;
                row-gap: 20px;
                height: fit-content;
            }
            .text{
                margin-left: 20px;
                margin-right: 20px;
                margin-top: 10px;
                margin-bottom: 0;
            }
            #photopres{
                max-width: 130px;
                min-width: 110px;
                margin-top : 0;
                margin-bottom : 20px;
            }

            .text p {
                font-size: clamp(15px, 2vw, 20px);
            }

            .title p{
                font-size: clamp(30px, 2vw, 40px);
            }

            #frames{
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            #frames iframe{
                width: 80%;
                height: 175;
            }
        }
    }
}