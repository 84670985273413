@import '../vars.scss';

html {
  scroll-behavior: smooth;
}

body:not(.index) {

  overflow-y: auto;

  & {
      background: $body-background-color;
      padding:0;
      margin:0;
  }

  /* Personnalisation du titre de page*/
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    font-weight: $title-font-weight;
    letter-spacing: 1px;
    margin: 0 auto;
    
    p {
      color: $title-color;
    }
  }

  * {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  h1 {
      text-align: center;
  }

  h3 {
      text-align: center;
  }

  li {
      list-style: none;
  }

  label{
      margin-bottom: 10px;
  }

  a:link { color: initial }          /* Liens non visités */
  a:visited { color: initial }     /* Liens visités */
  a:active { color: initial }       /*Liens actifs */

  /* Couleur de la selection */

  & * {
    &::selection, &::-moz-selection {
      color: $selection-color;
      background-color: $selection-background-color;
    }
  }


  &#dark {
  
    .title {
      p {
        color : $title-color-dark;
      }
    } 

    & * {
      color: white;
    }

    & {
      background : $body-background-color-dark;
    }

    & *::-moz-selection {
      color: $selection-color-dark;
      background-color: $selection-background-color-dark;
    }

    & *::selection {
      color: $selection-color-dark;
      background-color: $selection-background-color-dark;
    }

  }

}