@import '../vars.scss';

body.about {
    body {
        width: 100%;
        height: 100%;
    }
    
    .main-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .content {
        margin-top: 12vh;
        display: flex;
        flex-direction: column;
        background-color: $containers-background-color;
        transition: background-color $general-transition-switch-mode;
        width: 85%;
        height: fit-content;
        border-radius: 15px;
        box-shadow: $containers-box-shadow;
        column-gap: 2%;
        margin-bottom: 4vw;
    }
    
    .title {
        text-align : start;
        color: $title-color;
        margin: 0 !important;
        margin-left: 30px !important;
    }
    
    .text-content {
        margin: 1.5vw 4vw 1.5vw 8vw;
        font-weight: 300;
        text-align: justify;
        font-size: 1.4vw;
    }
    
    .title-container {
        display:flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    
    .title-container-first-child {
        margin-top: 2vw;
    }
    
    .text-content:last-child {
        margin-bottom: 3vw;
    }
    
    
    .title-container > img {
        width: 3.5vw;
        height: 3.5vw;
        margin-right: 2vw;
    }
    
    .subtitle {
        margin-left: 6vw;
        color : $title-color;
    }
    
    .bar {
        width: 30%;
        height: 0.4vw;
        border-radius: 10px;
        background-color: $title-color;
        margin: 0 auto;
        margin-top: 1vw;
    }
}