body:not(.index) {
    &#dark #startbackground {
        background-color: rgb(0, 0, 0) !important;
    }
    
    &#dark #background3 {
        background-image: url(../../img/backgrounds/background3-dark-theme.png) !important;
    }
    
    &#dark #background2 {
        background-image: url(../../img/backgrounds/background2-dark-theme.png) !important;
    }
    
    &#dark #background1 {
        background-image: url(../../img/backgrounds/background1-dark-theme.png) !important;
    }
}