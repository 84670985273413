@import '../vars.scss';

body#dark:not(.index) {

    &::-webkit-scrollbar-track {
        background-color: $dark-scrollbar-track-color;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $dark-scrollbar-color;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: $dark-scrollbar-color-hover;
    }

    /* Browser : Safari, Edge, Opera */
    & {
        scrollbar-color: $dark-scrollbar-color-hover $dark-scrollbar-track-color;
    }
}