@import '../vars.scss';

body.home#dark {
    *:not(.text-project-container *, .text-project-container, .title-card, .title-page-project > p, .card-back *) {
        color: white !important;
    }

    .page-title-part {
        color: rgb(232, 190, 190) !important;
    }

    .horizontal-bars {
        background-color: $bar-background-color-dark;
        box-shadow: $bar-box-shadow-dark;
    }

    .link-btn, .download-btn {
        &:hover {
            color: rgb(234, 215, 215) !important;
            transition: 0.2s;
        }
    }

    .content, #container-cv {
        background-color: $containers-background-color-dark;
        box-shadow : $containers-box-shadow-dark;
    }

    .blackbar {
        background-color: rgba(255, 255, 255);
        border: 2px solid rgba(255, 255, 255);
    }

    .cv-button {
        background-color: $cv-download-button-background-color-dark;
        background-image: url(../../img/home/icon/white-download-without-line.png);
        &:hover {
            background-color: $cv-download-button-background-color-hover-dark;
        }
    }

    $projects-data-date-background-color-dark-theme: rgba(255, 255, 255, 0.884);
    $projects-data-date-border-width: 9px;

    .projects {
        & > *::before {
            border-top: $projects-data-date-border-width solid $projects-data-date-background-color-dark-theme;
        }

        & > *::after {
            background-color: $projects-data-date-background-color-dark-theme;
            background-image: url(../../img/home/icon/calendar-black.png);
            color: black;
        }
    } 
    
    .school-competence-container .card {
        background-color : $containers-background-color-dark;
    }
    
    .card-top-container {
        box-shadow: $containers-box-shadow-dark;
    }
}