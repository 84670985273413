body:not(.index) {
    @media screen and (max-width: 1100px) {
        #background3 {
            bottom: 0vh;
        }

        @media screen and (max-width: 769px) {
            #background3 {
                bottom: -7vh;
            }
            @media screen and (max-width: 769px) {
                #background3 {
                    bottom: -4vh;
                }
            }
        }
    }
}