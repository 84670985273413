@import '../vars.scss';

body.index {

    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;

    overflow: hidden;

    #root {
        height: 100%;
    }

    main {
        width: 100%;
        height: 100%;
    }

    @font-face {
        font-family: 'Poppins';
        src: url('../../font/Poppins/Poppins3.woff2') format('woff2');
    }

    @font-face {
        font-family: 'Adobe';
        src: url('../../font/AdobeFont/Adobe.ttf') format('truetype');
    }

    @font-face {
        font-family: 'AdobeBold';
        src: url('../../font/AdobeFont/AdobeBold.ttf') format('truetype');
    }

    * {
        margin: 0;
        padding: 0;
        font-family: 'Poppins', sans-serif;
    }

    .background-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
        background-color: $index-background-color;
        transition: background-color $general-transition-switch-mode;
    }

    /* Triangles équilateraux */
    .triangle {
        position: absolute;
        width: 0;
        height: 0;
        transition: border-bottom $general-transition-switch-mode;
    }

    .triangle-red {
        left: 5vw;
        top: 5vw;
        border-left: 10vw solid transparent;
        border-right: 10vw solid transparent;
        border-bottom: 16vw solid;
        border-bottom-color: $index-triangle-red-background-color;
        animation: triangle-red-move 40s linear infinite;
        z-index: -1;
    }

    .triangle-yellow {
        right: 5vw;
        top: 5vw;
        border-left: 16vw solid transparent;
        border-right: 16vw solid transparent;
        border-bottom: 23vw solid;
        border-bottom-color: $index-triangle-yellow-background-color;
        animation: triangle-yellow-move 50s linear infinite;
        z-index: -2;
    }

    .triangle-orange {
        border-left: 20vw solid transparent;
        border-right: 20vw solid transparent;
        border-bottom: 27vw solid;
        border-bottom-color: $index-triangle-orange-background-color;
        bottom: 10vw;
        left: 15vw;
        animation: triangle-orange-move 70s linear infinite;
        z-index: -3;
    }

    .circle {
        position: absolute;
        border-radius: 50%;
        z-index: -4;
        transition: background-color $general-transition-switch-mode;
    }

    .circle-one {
        width: 100vw;
        height: 100vw;
        top: 35%;
        right: -70%;
        background-color: $index-circle-one-background-color;
    }

    .circle-two {
        width: 80vw;
        height: 80vw;
        top: -60%;
        left: -40%;
        background-color: $index-circle-two-background-color;
    }

    /* Animations */

    @keyframes triangle-red-move {
        0% {
            transform: rotate(0deg);
        }

        50% {
            transform: rotate(200deg) translateX(30vw) translateY(-8vw);
        }

        70% {
            transform: rotate(160deg) translateX(15vw) translateY(-10vw);
        }

        100% {
            transform: rotate(0deg);
        }
    }

    @keyframes triangle-yellow-move {
        0% {
            transform: rotate(0deg);
        }

        50% {
            transform: rotate(-220deg) translateX(-10vw) translateY(25vw);
        }

        80% {
            transform: rotate(-1deg) translateX(-7vw) translateY(12.5vw);
        }

        100% {
            tranform: rotate(0deg);
        }
    }

    @keyframes triangle-orange-move {
        0% {
            transform: rotate(0deg);
        }

        50% {
            transform: rotate(-170deg) translateX(30vw) translateY(25vw);
        }

        70% {
            transform: rotate(-160deg) translateX(15vw) translateY(12.5vw);
        }

        100% {
            transform: rotate(0deg);
        }
    }

    /* CSS DU MENU */

    .menu {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: fit-content;
        padding: 12px 0;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: end;
        margin-right: 5vw;
    }

    .hamburger-container input[type="checkbox"] {
        display: none;
        visibility: hidden;
        opacity: 0;
    }

    input[type="checkbox"]:checked~.hamburger-bar {
        box-shadow: 0 0 3.15rem #000000e9;
    }

    input[type="checkbox"]:checked~.top-bar {
        transform: rotate(-45deg);
        bottom: auto;
    }

    input[type="checkbox"]:checked~.middle-bar {
        opacity: 0;
    }

    input[type="checkbox"]:checked~.bottom-bar {
        transform: rotate(45deg);
        top: auto;
    }

    .get-start {
        position: absolute;
        z-index: 1;
        top: 10px;
        right: 1.5vw;
        width: 140px;
        height: 55px;
        border-radius: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2em;
        font-weight: 500;
        color: #ffffff;
        text-decoration: none;
        transition: all 0.3s ease;
        border: 2px solid #ffffff;
        box-shadow: 0 0 0 0 #ffffff;
        letter-spacing: 0.5px;
    }

    .get-start:hover {
        transition: all 0.3s ease;
        background-color: #edba9358;
    }

    .media-menu {
        display: none;
    }

    .menu li {
        list-style: none;
        display: inline-block;
    }

    .menu li a {
        text-decoration: none;
        color: #ffffff;
        font-size: 1.35em;
        font-weight: 600;
        text-shadow: 0 0 2.15rem #000;
        transition: all 0.3s ease;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .menu li a:hover {
        color: #7d4d4d;
        text-shadow: 0 0 1.15rem #00000094;
    }

    /* Quand on hover un ul une barre apparait sous le lien avec une animation */
    .menu ul li a::after {
        opacity: 0;
        border-radius: 5px;
        margin-top: 5px;
        content: "";
        display: block;
        width: 20%;
        height: 5px;
        background-color: #8f5c5c;
        transition: width 0.3s ease, opacity 0.3s ease;
    }

    .menu ul li a:hover::after {
        opacity: 1;
        width: 120%;
        transition: width 0.3s, opacity 0.3s ease;
    }

    .left-top-angular {
        position: absolute;
        left: 0;
        height: fit-content;
        width: fit-content;
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        .logo {
            width: 70px;

            img {
                width: 80%;
            }
        }
        .select-language {
            position: relative;
            width: fit-content;
            height: fit-content;

            .choice {

                & > img {
                    height: auto;
                    width: 90%;
                }
                display: grid;
                grid-template-columns: 60px 1fr 15px;
                align-items: center;
                justify-content: center;
                flex-direction: row;
                column-gap: 10px;
                backdrop-filter: blur(20px);
                border-radius: 10px;
                box-shadow: 0 0 2.15rem #00000043;
                height: 40px;
                width: 180px;
                padding: 5px 15px 5px 15px;
                font-size: 1.2em;
                font-weight: 600;
                color: #fff;
                text-decoration: none;
                transition: all 0.3s ease;
                cursor: pointer;

                img {
                    height: 35px;
                    border-radius: 5px;
                }

                .options-wrapper {
                    position: absolute;
                    left: 0;
                    top: 100%;
                    height:fit-content;
                    width: 100%;
                    overflow: hidden;
                }

                .options {
                    width: 100%;
                    height: fit-content;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    background-color: #ffffff33;
                    backdrop-filter: blur(20px);
                    color: black;
                    border-radius: 10px;
                    /* box-shadow: 0 0 2.15rem #00000052; */
                    z-index: -1;
                    pointer-events: none;
                    opacity: 0;
                    display: flex;
                    transform: translateY(-100%);
                    transition: all 0.15s ease;
    
                    &.active {
                        opacity: 1;
                        pointer-events: all;
                        transform: translateY(0);
                    }
    
                    .option {
                        width: 100%;
                        height: 50px;
                        display: grid;
                        grid-template-columns: 85px 1fr;
                        align-items: center;
                        align-content: center;
                        color: #000;
                        text-decoration: none;
                        transition: all 0.3s ease;
                        cursor: pointer;
                        border-radius: 10px;
                        column-gap: 10px;
                        text-shadow: none;

                        img {
                            align-self: center;
                            justify-self: center;
                        }
    
                        &:hover {
                            background-color: hsla(30, 5%, 25%, 0.062);
                            transition: all 0.3s ease;
    
                        }
                    }
                }
            }

        }
    }

    footer {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: fit-content;
        z-index: 0;
    }

    .about-page {
        position: absolute;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        bottom: 1.5vw;
        right: 2vw;
        width: fit-content;
        height: fit-content;
        font-size: 1.5em;
        font-weight: 600;
        text-decoration: none;
        color: #fff;
        transition: all 0.3s ease;
        text-shadow: 0 0 1.15rem #000;
    }

    .about-page>p {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .about-page>p::after {
        content: "";
        display: block;
        width: 95%;
        height: 6px;
        background-color: #de8ea7;
        transition: width 0.1s ease-in, background-color 0.1s ease-in, height 0.1s ease-in, margin-bottom 0.1s ease-in;
        border-radius: 50px;
    }

    .about-page>p:hover::after {
        width: 70%;
        height: 5px;
        margin-bottom: 1px;
        background-color: #e5779a;
        transition: width 0.1s ease-in, background-color 0.1s ease-in, height 0.1s ease-in, margin-bottom 0.1s ease-in;
    }

    .container {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .container > .title > h1 {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12vw;
        height: 10vw;
        margin-bottom: 2vw;
        font-weight: 600;
        color: #fbf6e9;
        text-shadow: 0 0 2.15rem #00000093;
        font-family: 'AdobeBold';
        transition: color $general-transition-switch-mode;
    }

    .container>.title>.subtitle>h2 {
        font-size: 6vw;
        font-weight: 600;
        color: #fff;
        font-family: 'Adobe';
        text-shadow: 0 0 2.15rem #00000093;
    }

    .title>.main-bar {
        width: 70%;
        height: 8px;
        border-radius: 10px;
        background-color: #fff;
        box-shadow: 0 0 2.15rem #000000e9;
        z-index: 1;
    }

    .border-triangle {
        filter: blur(6px);
    }

    .footer-triangle {
        position: absolute;
        bottom: -1vw;
        left: 0;
        width: 0;
        height: 0;
        border-left: 100vw solid transparent;
        border-right: 0vw solid transparent;
        border-bottom: 21vw solid $index-triangle-footer-background-color;
        z-index: 0;
    }

    .header-triangle {
        position: absolute;
        top: -1vw;
        left: 0;
        width: 0;
        height: 0;
        border-left: 100vw solid transparent;
        border-right: 0vw solid transparent;
        border-top: 12vw solid $index-triangle-header-background-color;
        filter: blur(6px) drop-shadow(0 0 0.5rem #000000e9);
        z-index: 1;
    }

    @keyframes bar-move {
        0% {
            width: 73%;
        }

        50% {
            width: 70%;
        }

        100% {
            width: 73%;
        }
    }

    .subtitle {
        margin-top: 2vw;
        width: 100%;
        height: 6vw;
        display: flex;
        justify-content: start;
        align-items: center;
        flex-direction: row;
        column-gap: 1vw;
    }

    .vertical-bar {
        width: 0.5vw;
        height: 6vw;
        background-color: #000000;
        border-radius: 10px;
        animation: vertical-bar-apparition 1.5s ease-in-out infinite;
        transition: all 0.3s ease;
        z-index: 1;
    }

    .title {
        display: flex;
        flex-direction: column;
        row-gap: 0;
    }

    @keyframes vertical-bar-apparition {
        0% {
            opacity: 1;
        }

        40% {
            opacity: 0;
        }

        50% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    .download-img {
        width: 1.9vw;
        height: 1.9vw;
        transition: background-image 0.18s ease;
        background-size: cover;
        background-position: center;
    }

    .dl-img {
        background-image: url(../../img/index/download.png);
    }

    .footer-links {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        column-gap: 8vw;
        margin-bottom: 20px;
        position: relative;
        z-index: 0;
    }

    .footer-links li {
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .footer-links a {
        color: #fff;
        text-decoration: none;
        transition: all 0.4s ease;
        text-shadow: 0 0 1.15rem #000;
        width: 5vw;
        height: 5vw;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .footer-links a:hover {
        transition: all 0.2s ease;
    }

    .linkedin-link {
        background-image: url(../../img/index/icon-linkedin.png);
    }

    .linkedin-link:hover {
        background-image: url(../../img/index/icon-linkedin-hover.png);
    }

    .github-link {
        background-image: url(../../img/index/icon-github.png);
    }

    .github-link:hover {
        background-image: url(../../img/index/icon-github-hover.png);
    }

    .gitlab-link {
        background-image: url(../../img/index/icon-gitlab.png);
    }

    .gitlab-link:hover {
        background-image: url(../../img/index/icon-gitlab-hover.png);
    }

    .mail-link {
        background-image: url(../../img/index/icon-mail.png);
    }

    .mail-link:hover {
        background-image: url(../../img/index/icon-mail-hover.png);
    }

    .tel-link {
        background-image: url(../../img/index/icon-tel.png);
    }

    .tel-link:hover {
        background-image: url(../../img/index/icon-tel-hover.png);
    }

    .hamburger-container {
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: relative;
        right: 190px;
    }

    .hamburger-bar {
        position: absolute;
        width: 50px;
        height: 5px;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 0 2.15rem #000000e9;
        transition: all 0.2s ease;
    }

    .top-bar {
        bottom: 10px;
    }

    .bottom-bar {
        top: 10px;
    }

    .menu-links {
        display: flex;
        width: 100%;
        height: fit-content;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        column-gap: 50px;
        margin-top: 15px;
    }

    .menu-links.active {
        display: flex;
    }

    .menu-links li {
        opacity: 0;
        transform: translateX(80px);
        transition: transform 0.1s ease, opacity 0.1s ease;
    }

    .menu-links.active li {
        opacity: 1;
        transform: translateX(0px);
        transition: transform 0.1s ease, opacity 0.1s ease;
    }

    .btn-switch-mode {
        position: absolute;
        bottom: 1.7vw;
        left: 1.7vw;
        z-index: 3;
        width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .btn-switch-mode button {
        width: 60px;
        height: 55px;
        border: none;
        cursor: pointer;
        transition: all 0.2s ease;
        border: 2px solid #000000;
        background-position: center;
        background-repeat: no-repeat;
    }

    .btn-switch-mode>.media-light-theme-form {
        transform: translateX(-2px);
    }

    .btn-switch-mode button {
        transition: all 0.2s ease;
    }

    .btn-switch-mode>.media-dark-theme-form>button {
        transition: all 0.2s ease;
        background-color: transparent;
        background-size: 50%;
        border-radius: 100% 0 0 100%;
        background-image: url(../../img/index/dark-black.png);
    }

    .btn-switch-mode>.media-dark-theme-form>button:hover {
        background-color: #e6d1be52;
        background-image: url(../../img/index/dark-hover-black.png);
    }


    .btn-switch-mode>.media-light-theme-form>button {
        transition: all 0.2s ease;
        background-size: 60%;
        border-radius: 0 100% 100% 0;
        background-color: #e6d1beb9;
        background-image: url(../../img/index/light-hover-black.png);
    }

    .btn-switch-mode>.media-light-theme-form>button:hover {
        background-color: #d1af92b9;
        background-image: url(../../img/index/light-black.png);
    }
}