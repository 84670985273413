@import '../vars.scss';

body:not(.index) {
    &#dark footer {
        background-color: $components-background-color-dark;
        box-shadow: $components-box-shadow-dark;
    }
    
    &#dark #footer1>.point-footer-container>.point-footer {
        color: rgb(255, 255, 255) !important;
    }
    
    
    &#dark #footergithubimg {
        background-image: url(../../img/footer/github-white.png);
    }
    
    &#dark #footerlinkedinimg {
        background-image: url(../../img/footer/linkedin-white.png);
    }
    
    &#dark #footermailimg {
        background-image: url(../../img/footer/mail-white.png);
    }
    
    &#dark #footerphoneimg {
        background-image: url(../../img/footer/phone-white.png);
    }
}