@import '../vars.scss';

body.contact#dark {
    *:not(.required, textarea, input) {
        color: white !important;
    }
    
    .formulaire {
        background-color : $form-background-color-dark;
        box-shadow : $form-box-shadow-dark;
    }
    
    .buttons-container {
        & > button {
            background-color : $form-submit-button-background-color-dark;

            &:hover {
                background-color : $form-submit-button-background-color-hover-dark;
            }
        }
    }
    
    #pres {
        background-color: $colored-container-background-color-dark;
        box-shadow : $colored-container-box-shadow-dark;
    }
    
    input, textarea {
        color: rgb(255, 255, 255) !important;

        &:focus {
            animation: appearShadowDark 0.2s forwards;
      
            $darkBoxShadowColor: $form-submit-button-background-color-dark;
            @keyframes appearShadowDark {
              from {
                box-shadow: 0 0 0 0 $darkBoxShadowColor;
              }
              to {
                box-shadow: 0 0 0 2.5px $darkBoxShadowColor;
              }
            }
        }

        &::placeholder {
            color: rgb(177, 177, 177) !important;
        }
    }

    .spinner > div {
        background-color: rgb(255, 255, 255) !important;
    }

    .required {
        color : #f37b7b !important;
    }
}
