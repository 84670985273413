@import '../vars.scss';

body:not(.index) {

    &.no-scroll {
        overflow-y: hidden;
    }

    /* Browser : Firefox, Chrome */
    &::-webkit-scrollbar {
        width: $scrollbar-width;
    }
    
    &::-webkit-scrollbar-track {
        background-color: $scrollbar-track-color;
    }
    
    &::-webkit-scrollbar-thumb {
        border-radius: 15px;
        background-color: $scrollbar-color;
    }
    
    &::-webkit-scrollbar-thumb:hover {
        background-color: $scrollbar-color-hover;
    }
    
    /* Browser : Safari, Edge, Opera */
    
    & {
        scrollbar-color: rgb(219, 151, 151) rgb(230, 230, 230);
        scrollbar-width: $scrollbar-width;
        overflow-x: hidden;
        overflow-y: scroll;
    }
}