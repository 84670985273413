@import '../vars.scss';

body#dark #loaderContainer {
    * {
        color: white !important;
    }
    
    #background {
        background-color: $start-background-color-dark;
    }
    
    #container {
        background-color: $loader-background-color-dark;
        box-shadow: $loader-box-shadow-dark;
    }
}