@import '../vars.scss';

html.background {
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
}

body.background {

    $margin-background-position: 20%;
    $size-semester: 400px;
    $border-radius-semester: 20px;
    $arrow-size: 35px;
    $proportional-arrow-size: 0.85;
    $point-width : 30px;
    $explain-medal-img-width: 90px;
    $view-height: 190px;

    header {
        position: absolute !important;
    }

    .wmde-markdown {
        background-color: transparent !important;
    }

    .explain-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;

        * {
            margin: 0;
        }

        .explain {
            width: 800px;
            height: 260px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            position: relative;
            z-index: 1;
            background-color: $colored-container-background-color;
            box-shadow: $colored-container-box-shadow;
            padding: 30px;
            border-radius: 20px;
            color: white;
            transition: background-color $general-transition-switch-mode;

            a:hover {
                background-color: $colored-containers-background-color-hover;
                transition: 0.2s;
            }

            p.explain-text {
                color: #e4e4e4;
            }

            .medal-img {
                width: $explain-medal-img-width;
                filter: drop-shadow(0 0 15px rgba(0, 0, 0, 0.6));
                height: fit-content;
                position: absolute;
                top: calc(-1 * $explain-medal-img-width / 3);
                left: calc(-1 * $explain-medal-img-width / 2.5);
            }
        }

        p.explain-text {
            font-size: 24px;
            text-align: center;
            color: #515151;
        }

        a.explain-text {
            transition: 0.2s;
            display: flex;
            flex-direction: row;
            column-gap: 20px;
            align-items: center;
            justify-content: center;
            padding: 7px 20px;
            border-radius: 10px;
            text-decoration: none;
            color: white;
            font-weight: bold;
            font-size: 25px;

            /* Animation sur l'icone view */
            .arrow-view-container {
                transform: translateY(-10%);

                .arrow-view {
                    display: block;
                    width: 10px;
                    height: 10px;
                    border-bottom: 4px solid #fff;
                    border-right: 4px solid #fff;
                    transform: rotate(45deg);
                    animation: scroll 2s infinite;

                    &:last-child {
                        animation-delay: +0.2s;
                    }
                }
            }
        }
    }

    .hidden {
        display: none;
    }
    
    #parallax-semesters{
        height: 100vh;
        width: 100vw;
    }
    
    #fordisplay{
        height: 100vh;
        width: 100vw;
        display: grid;
        grid-template-columns: 22.44vw 87.66vw;
        grid-template-rows: 1fr;
        justify-items: center;
        align-items: center;
        position: absolute;
        top: 10vh;
    }
    
    #timeline {
        height: 90vh;
        width: 15px;
        background-color:rgba(255, 255, 255, 0.9);
        backdrop-filter: blur(10px);
        box-shadow : 0 10px 50px rgb(0 0 0 / 20%);
        border-radius: 20px;
        position: fixed;
        top: 5vh;
        bottom: 5vh;
        left:10.7vw;
        z-index: -1;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 20px;
            background: linear-gradient(50deg, rgba(255, 255, 255, 0.2) 40%, rgba(218, 162, 134, 0.8) 50%, rgba(255, 255, 255, 0.4) 60%);
            background-size: 200% 200%;
            background-position-y: 0;
            filter: blur(5px);
            -webkit-filter: blur(5px);
            animation: moveLight 4.5s ease-in-out infinite forwards;
            z-index: 2;
        }
    }
    
    @keyframes moveLight {
        0% {
            background-position-y: calc(0% - $margin-background-position);
            opacity: 0.3;
        }
    
        30% {
            opacity: 0.5
        }
    
        50% {
            opacity: 0.8;
        }
    
        70% {
            opacity: 0.5;
        }
    
        100% {
            background-position-y: calc(100% + $margin-background-position);
            opacity: 0.3;
        }
    }

    #semesters{
        width: fit-content;
        align-self: center;
        margin-right: 35vw;
        margin-left: 50vw;
        margin-top: 93vh;
        position: absolute;
        top: 0;
        display: flex;
        flex-direction: column;
        row-gap: 0px;

        div#view {
            width: $size-semester;
            height: $view-height;
            position: absolute;
            top: calc($view-height) * -1;
            opacity: 0;
        }

        .semester{
            width: $size-semester;
            min-height: $size-semester;
            height: fit-content;
            background-color: $containers-background-color;
            border-radius: $border-radius-semester;
            display: flex;
            flex-direction: column;
            row-gap: 15px;
            /* box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3); */
            backdrop-filter: blur(4px);
            margin-bottom: 100px;
            cursor: pointer;
            transition: background-color 0.3s, transform 2.5s ease-in-out;

            &:not(.selected):hover{
                backdrop-filter: blur(7px);
                background-color:rgb(235 217 210 / 45%);
            }

            p {
                margin: 0 7%;
            }

            &.selected {
                box-shadow: 0 0 0 4px $bottom-semester-background-color;
                background-color: $bottom-semester-background-color;
            }

            .bottom-semester-container {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 300px;
                width: 100%;
                border-radius: 0 0 $border-radius-semester $border-radius-semester;
                position: absolute;
                bottom: 0;
                left: 0;
                overflow: hidden;

                .bottom-semester {
                    position: absolute;
                    bottom: -123px;
                    left: 0px;
                    width: 113%;
                    height: 207px;
                    background-color: $bottom-semester-background-color;
                    rotate: 173deg;
                    z-index: -1;
                }
            }

            .semester-description {
                text-align: justify;
                color: black;
            }
        }

        .title-semester {
            margin: 0;
        }

        .title-semester-container {
            display: flex;
            flex-direction: row;
            column-gap: 10px;
            justify-content: center;
            align-items: center;
            min-height: 80px;
            height: 80px;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.1);
            border-radius: 20px 20px 0 0;
            position: relative;

            & > img {
                height: 40px;
                width: 40px;
            }

            .arrow-container {
                position: absolute;
                top: 0;
                right: 0;
                width: 20%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1;

                .arrow {
                    background-image: url(../../img/background/arrow.png);
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    width: 0;
                    height: 0;
                    transition: 0.3s;

                    &:hover {
                        filter: invert(1);
                    }
                    &.active {
                        width: $arrow-size;
                        height: $arrow-size;
                        transition: 0.3s;
                        animation: arrow-fade 1.5s ease-in-out infinite forwards, arrow 1.5s ease-in-out infinite forwards;
                    }
                }

                &:hover {
                    .arrow {
                        &.active {
                            opacity: 0.7 !important;
                            animation: none;
                            -moz-animation: none;
                            -webkit-animation: none;
                        }
                    }
                }
            }
        }

    }
    @keyframes arrow {
        0% {
            width: calc($arrow-size) * $proportional-arrow-size;
            height: calc($arrow-size) * $proportional-arrow-size;
            opacity: 0;
        }

        50% {
            width: $arrow-size;
            height: $arrow-size;
            opacity: 1;
        }

        100% {
            width: calc($arrow-size) * $proportional-arrow-size;
            height: calc($arrow-size) * $proportional-arrow-size;
            opacity: 0;
        }
    }

    @keyframes scroll {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
    }

    #points {
        display: flex;
        flex-direction: column;
        row-gap: 470px;
        margin-top: 117vh;
        z-index: 0;

        
        .point-container {
            width: $point-width;
            height: $point-width;
            display: flex;
            justify-content: center;
            align-items: center;

            &::after {
                content: attr(datadate);
                position: absolute;
                margin-bottom: 100px;
                backdrop-filter: blur(2px);
                background-color: rgba(0, 0, 0, 0.6);
                background-image: url(../../img/background/calendar.png);
                background-repeat: no-repeat;
                background-position-x: 90%;
                background-position-y: center;
                background-size: 20px;
                border-radius: 5px;
                color: white;
                font-weight: 500;
                padding: 7px 40px 7px 10px;
                pointer-events: none;
                opacity: 0;
                transition: opacity 0.25s;
            }

            &:hover::after, &.focus::after {
                opacity: 1;
                transition: opacity 0.25s;
            }

            &:hover, &.focus {
                .point {
                    box-shadow: 0 0 10px 0 rgb(0, 0, 0);
                    background: linear-gradient(160deg, transparent 60%, $bottom-semester-background-color 40%);
                    backdrop-filter: blur(10px);
                }
            }

            .point{
                height: $point-width;
                width: $point-width;
                border-radius: 50%;
                box-shadow: 0 0 5px 0 rgb(0, 0, 0);
                background: linear-gradient(160deg, transparent 60%, rgba(0,0,0,1) 40%);
                backdrop-filter: blur(5px);
                transition: box-shadow 0.3s;
                cursor: pointer;
                position: relative;
                z-index: 0;
            }
        }
    }
}
